import type { StandardListingAgreement } from './types'

export const getPremiumSaleText = (listingDate: Date, {
    address,
    city,
    state,
    zipCode,
    price,
    financialTerms,
    mlsName,
    commission,
    ownerName,
    coownerNames,
    companyName
}: StandardListingAgreement) => {
    const title = 'Exclusive Right of Sale Listing Agreement'

    const subtitle = `This Exclusive Right of Sale Listing Agreement ("Agreement") is between ${ownerName}${coownerNames ? `, ${coownerNames}` : ''}${companyName ? ` / ${companyName}` : ''} ("SELLER") and Propertio Inc ("BROKER"), the licensed real estate broker providing brokerage services through the  www.helloReeve.com platform ("Website").`
    const blocks = [
        {
            mainBolded: '1. Authority to Sell Property:',
            main: `SELLER gives BROKER the EXCLUSIVE RIGHT TO SELL the real and personal property (collectively "Property") described below, at the price and terms described below, beginning on ${listingDate.toLocaleDateString('en-US')} and terminating at 11:59 p.m. on ${new Date(new Date(listingDate).setFullYear(listingDate.getFullYear() + 1)).toLocaleDateString('en-US')} ("Termination Date"). Upon full execution of a contract for sale and purchase of the Property, all rights and obligations of this Agreement will automatically extend through the date of the actual closing of the sales contract. SELLER and BROKER acknowledge that this Agreement does not guarantee a sale. \n This Property will be offered to any person without regard to race, color, religion, sex, handicap, familial status, national origin, or any other factor protected by federal, state, or local law. SELLER certifies and represents that she/he/it is legally entitled to convey the Property and all improvements.`
        },
        {
            mainBolded: '2. Description of Property:',
            main: `${address}, ${city}, ${state} ${zipCode}`
        },
        {
            mainBolded: '3. Price and Terms:',
            main: 'The property is offered for sale on the following terms or on any other terms acceptable to SELLER:',
            sub: [
                `(a) Price: $${price}`,
                `(b) Financing Terms: ${financialTerms}`]
        },
        {
            mainBolded: '4. BROKER Obligations:',
            main: 'BROKER agrees to make diligent and continued efforts to sell the Property until a sales contract is pending on the Property.'
        },
        {
            mainBolded: '5. Multiple Listing Service:',
            main: 'Placing the Property in a multiple listing service (the "MLS") is beneficial to SELLER because the Property will be exposed to a large number of potential buyers. As a MLS participant, BROKER is obligated to timely deliver this listing to the MLS. This listing will be promptly published in the MLS unless SELLER directs BROKER otherwise in writing. SELLER authorizes BROKER to report to the MLS this listing information and price, terms, and financing information on any resulting sale for use by authorized Board / Association members and MLS participants and subscribers.'
        },
        {
            mainBolded: '6. BROKER Authority:',
            main: 'SELLER authorizes BROKER to',
            sub: ['(a) Advertise the Property as BROKER deems advisable including advertising the Property on the Internet',
                '(b) Place appropriate transaction signs on the Property, including "For Sale" signs and "Sold" signs (once SELLER signs a sales contract) and use SELLER\'s name in connection with marketing or advertising the Property.',
                '(c) Obtain information relating to the present mortgage(s) on the Property',
                '(d) Provide objective comparative market analysis information to potential buyers.',
                '(e) If SELLER decides to use a lock box so that licensed real estate agents can access the property, SELLER acknowledges that a lock box does not ensure the Property\'s security; SELLER is advised to secure or remove valuables. SELLER agrees that the lock box is for the SELLER\'s benefit and releases BROKER, persons working through BROKER and BROKER\'S Board Association members and MLS participants and subscribers from all liability and responsibility in connection with any loss that occurs.',
                '(f) Withhold verbal offers and withhold all offers once SELLER accepts a sales contract for the Property.',
                '(g) Act as a transaction broker.',
                '(h) Virtual Office Websites: Some real estate brokerages offer real estate brokerage services online. These websites are referred to as Virtual Office Websites ("VOWs"). An automated estimate of market value or reviews and comments about a property may be displayed in conjunction with a property on some VOWs. Anyone who registers on a VOW may gain access to such automated valuations or comments and reviews about any property displayed on a VOW. Unless limited below, a VOW may display automated valuations or comments and reviews about this Property.',
                '(i) BROKER should not be relied upon or held responsible for the following: to be physically present at Property inspections, Property appraisals, closing, or anything else requiring physical presence of BROKER at the Property or any other specific physical location, or be held responsible for delivery of keys.'
            ]
        },
        {
            mainBolded: '7. SELLER Obligations:',
            main: "In consideration of BROKER's obligations, SELLER agrees to:",
            sub: ['(a) Cooperate with BROKER in carrying out the purpose of this Agreement, including referring immediately to BROKER all inquiries regarding the Property\'s transfer, whether by purchase or any other means of transfer.',
                '(b) Make the Property available for BROKER to show during reasonable times.',
                '(c) Inform BROKER before leasing, mortgaging, or otherwise encumbering the Property',
                '(d) Indemnify BROKER and hold BROKER harmless from losses, damages, costs, and expenses of any nature, including attorney\'s fees, and from liability to any person, that BROKER incurs because of (1) SELLER\'s negligence, representations, misrepresentations, actions, or inactions; (2) the use of a lock box; (3) the existence of undisclosed material facts about the Property; or (4) a court or arbitration decision that a broker who was not compensated in connection with a transaction is entitled to compensation from BROKER. This clause will survive BROKER\'S performance and the transfer of title.',
                '(e) If at any time BROKER should become liable to SELLER or buyer by virtue of the Contract or the transactions contemplated by it, such BROKER liability shall be limited to a sum not to exceed the upfront flat fee MLS payment received or the commission received by such BROKER as a result of this contract or Transaction, whichever is greater; and this sum shall be complete and exclusive. BROKER shall pay all costs incurred in any enforcement action.',
                '(f) Perform any act reasonably necessary to comply with FIRPTA (Section 1445 of the Internal Revenue Code).',
                '(g) Make all legally required disclosures, including all facts that materially affect the Property\'s value and are not readily observable or known by the buyer. SELLER certifies and represents that SELLER knows of no such material facts (local government building code violations, unobservable defects, etc.) other than except those expressly set forth in the SELLER\'s Real Property Disclosure Statement. SELLER will immediately inform BROKER of any material facts that arise after signing this Agreement.',
                '(h) SELLER agrees to pay compensation amount listed in Section: "COMPENSATION" to a buyer\'s agent who has shown the property to a specific buyer; And that buyer subsequently enters into a contract for sale and purchase with SELLER and successfully closes transaction. SELLER agrees that a buyer\'s agent who shows the property to a specific buyer thus establishes procuring cause. SELLER agrees that any attempt to circumvent a buyer\'s agent commission by not including the compensation in the HUD closing statement that is owed to buyer\'s agent who has shown procuring cause, will be considered a breach of this agreement. Additionally, should closing agent fail to properly display on closing statement (Alta Closing Disclosure/HUD) and collect BROKER compensation amount listed in Section: "COMPENSATION" of this agreement, BROKER may take any of the following actions to collect payment:',
                '\t (i) file small claims or any claim (or suit) in any court for collection including repayment for courts costs and BROKER\'S attorney fees;',
                '\t (ii) SELLER hereby grants BROKER and BROKER reserves the right to place a lien on any Property of SELLER where SELLER has an equitable interest, should BROKER determine that SELLER is attempting to circumvent paying the agreed upon compensation that is rightfully due to a buyer\'s agent or fails to pay BROKER compensation amount as listed in Section: "COMPENSATION" of this agreement.',
                '\t (iii) For purposes of this paragraph, BROKER will be treated as a party to contract signed between Buyer and SELLER. Should BROKER incur costs or damages resulting directly from breach including board of Realtors arbitration findings regarding procuring cause and the payment of commission due, these BROKER liabilities are transferable to SELLER.',
                '(i) Consult appropriate professionals for related legal, tax, property condition, environmental, foreign reporting requirements, and other specialized advice'
            ]
        },
        {
            mainBolded: '8. Compensation',
            main: 'SELLER will compensate BROKER as specified below for procuring a buyer who is ready, willing, and able to purchase the Property or any interest in the Property on the terms of this Agreement or on any other terms acceptable to SELLER. SELLER will pay BROKER as follows:',
            sub: ['(a) LISTING BROKER COMPENSATION: $ 699.00 non-refundable upfront fee plus $2,300.00 at-close flat fee commission, no later than the date of closing specified in the sales contract. However, closing is not a prerequisite for BROKER\'s fee being earned.',
                `(b) SELLING BROKER (BUYER'S BROKER) COMPENSATION: SELLER is offering ${commission} commission in the MLS to procuring broker/licensee. SELLER is authorizing BROKER to offer this commission by means of this listing agreement and further authorizes BROKER to advertise such commission offer in the MLS on behalf of the SELLER to brokers in an effort to procure a buyer for Property. If a sales contract is fully executed by BUYER/SELLER or if SELLER, by means of any agreement, transfers title of Property to another party in which a broker was the procuring cause by evidence that the broker's name is on the sales contract, and successfully closes transaction, SELLER gives broker the authority to inform the title company/closing agent or attorney representing SELLER to include such commission/bonus, exactly as stated in the MLS, on the HUD1 settlement statement, as an item to be paid by the SELLER, and gives the closing agent such authority to disburse said commission/bonus to the procuring broker at closing. The only exception to this would be if SELLER and broker have agreed to other commission/bonus terms than what is being advertised in the MLS by attaching this language as an addendum to the final contract. If the property is sold to a buyer without the assistance of a broker, there is no additional compensation offered. Notice to Seller: The buyer's broker, even if compensated by Seller or Broker, may represent the interests of the buyer.`,
                '(c) Broker\'s fee is due in the following circumstances: (1) If any interest in the Property is transferred, whether by sale, lease, exchange, governmental action, bankruptcy, or any other means of transfer, regardless of whether the buyer is secured by Seller, Broker, or any other person. (2) If Seller refuses or fails to sign an offer at the price and terms stated in this Agreement, defaults on an executed sales contract, or agrees with a buyer to cancel an executed sales contract. (3) If, within 90 days after Termination Date ("Protection Period"), Seller transfers or contracts to transfer the Property or any interest in the Property to any prospects with whom Seller, Broker, or any real estate licensee communicated regarding the Property before Termination Date. However, no fee will be due Broker if the Property is relisted after Termination Date and sold through another broker.',
                '(d) Retained Deposits: As consideration for Broker\'s services, Broker is entitled to receive 50% of all deposits that Seller retains as liquidated damages for a buyer\'s default in a transaction, not to exceed the Paragraph 8(a) fee.'
            ]
        },
        {
            mainBolded: '9. Brokerage Relationship:',
            main: 'BROKER will act as a transaction broker. BROKER will deal honestly and fairly; will account for all funds; will use skill, care, and diligence in the transaction; will disclose all known facts that materially affect the value of the residential property which are not readily observable to the buyer; will present all offers and counteroffers in a timely manner unless directed otherwise in writing; and will have limited confidentiality with SELLER unless waived in writing. '
        },
        {
            mainBolded: '10. Dispute Resolution:',
            main: 'This Agreement will be construed under Florida law. All controversies, claims, and other matters in question between the parties arising out of or relating to this Agreement or the breach thereof will be settled by first attempting mediation under the rules of the American Mediation Association or other mediator agreed upon by the parties. If litigation arises out of this Agreement, the prevailing party will be entitled to recover reasonable attorney\'s fees and costs, unless the parties agree that disputes will be settled by arbitration as follows: Arbitration: Seller, Sales Associate, and Broker agree that disputes not resolved by mediation will be settled by neutral binding arbitration in the county in which the Property is located in accordance with the rules of the American Arbitration Association or other arbitrator agreed upon by the parties. Each party to any arbitration (or litigation to enforce the arbitration provision of this Agreement or an arbitration award) will pay its own fees, costs, and expenses, including attorney\'s fees, and will equally split the arbitrator\'s fees and administrative fees of arbitration.'
        },
        {
            mainBolded: '11. Miscellaneous:',
            main: 'This Agreement is binding on Seller\'s and Broker\'s heirs, personal representatives, administrators, successors, and assigns. Broker may assign this Agreement to another listing office. This Agreement is the entire agreement between Seller and Broker. No prior or present agreements or representations will be binding on Seller or Broker unless included in this Agreement. Electronic signatures are acceptable and will be binding. Signatures, initials, and modifications communicated by facsimile will be considered as originals. The term "buyer" as used in this Agreement includes buyers, tenants, exchangors, optionees, and other categories of potential or actual transferees.'
        },
        {
            mainBolded: '12. Additional Terms:',
            main: '',
            sub: [
                '(a) "Cancel any time, no penalty, no questions asked":SELLER reserves the right to cancel this agreement at any time, without any additional fee or penalty. However, cancellation of agreement does not release SELLER from obligations under Paragraphs 7 and 8 above. No refund of fees already paid will be issued upon cancellation if the service has been provided and the property has already been listed in the MLS. A full refund will be issued if cancellation occurs immediately after placing the order and before the property being entered in the MLS.',
                '(b) "Free and timely listing updates" : BROKER will perform timely updates to the MLS listing as needed and requested by SELLER throughout the listing agreement, at no additional cost to SELLER. All changes must be requested by SELLER through the website.',
                '(c) "Unauthorized listing" : The BROKER reserves the right to cancel the listing if misleading or false information has been provided, if the listing party has impersonated the actual owner without authorization, if there is any legal matter that hinders the free sale of the property or presents a legal risk for the BROKER. No refund will be issued in this case, when the BROKER decides to cancel the listing unilaterally under this paragraph.',
                '(d) "Copyrighted artwork" :  Images and photographs downloaded from anywhere on the Internet (including from previous listings) are "Copyrighted" and including them in your photo set submitted to BROKER without proper written authorization from the owner of the rights puts SELLER and BROKER at legal risk. Should BROKER receive a violation notice from the MLS or the Realtors Association regarding Copyrighted or unauthorized material posted in the listing, an email notice will be sent to the owner for correction. If no satisfactory correction is submitted within 24 hours, BROKER reserves the right to cancel the listing. No refund will be issued in this case. Customer releases, holds harmless and indemnifies the BROKER form and of any and all liability and responsibility for the publishing of any copyrighted material and will release the BROKER form any legal action (including complains to the Association or Realtors or MLS) that might arise from such usage.',
                '(e) "Free listing agreement extension until sold" : Should the Property not sell within the initial listing term, the BROKER agrees to extend the listing agreement for an additional term of up to 12 months, at SELLER\'s request, immediately prior to initial expiration, and at no additional cost to SELLER.',
                '(f) "Protection period" : For ninety (90) days following expiration of this Agreement, any cooperating broker shall continue to be entitled to the compensation described in this Agreement should SELLER transfer Property or any interest in the Property to any ready, willing and able prospects procured regarding the Property prior to expiration.',
                '(g) "Abandoned listing" : Should the SELLER repeatedly cease to respond to prospective buyer(s)/agent(s) inquiries and BROKER\'s attempts to contact (via email and telephone) for more than 2 weeks, the listing shall be considered "abandoned" and BROKER shall have the right to cancel the listing. No refund will be issued in this case.'
            ]
        }
    ]

    const acknowledgement = [
        `The SELLER has reviewed the foregoing information contained in this Data Entry Form and acknowledges the information to be true and correct to the best knowledge of the SELLER. The SELLER agrees to indemnify and hold harmless the ${mlsName}, the Originating Board, and their employees, the Listing BROKER and licensees, the Selling BROKER and licensees, and all other cooperating BROKERs and licensees against any and all claims or liability (including attorney fees) arising from any breach of warranty by SELLER or from any incorrect information supplied by SELLER or from any facts concerning the Property which was known or reasonably should have been known to SELLER but not disclosed by SELLER. At the request of the Listing BROKER, unless otherwise properly indicated on this Data Entry form,${mlsName} will electronically transmit information about the SELLER property to Internet web sites to aid in the marketing of the Property for sell.${mlsName} shall retain and make available all such data and photographs to all its participants for an indefinite period. ${mlsName} assumes no responsibility or liability to SELLER for errors or omissions on this Data Entry Form or in the ${mlsName} computer system. The SELLER here by authorizes Listing BROKER to file this Data Entry form with the ${mlsName} and the SELLER will provide timely notice of all status changes.`,
        `Copy returned to SELLER on ${listingDate.toLocaleDateString('en-US')} by email and available to SELLER at any time in SELLER's personal dashboard on the website.`
    ]

    return { blocks, acknowledgement, title, subtitle }
}
