import type { StandardListingAgreement } from './types'

export const getStandardSaleText = (listingDate: Date, {
    address,
    city,
    state,
    zipCode,
    price,
    financialTerms,
    mlsName,
    commission,
    ownerName,
    coownerNames,
    companyName
}: StandardListingAgreement) => {
    const title = 'Limited Service Sale Listing Agreement, Disclosures, and Disclaimers'
    const subtitle = `This Limited Service Listing Agreement ("Agreement") is between ${ownerName}${coownerNames ? `, ${coownerNames}` : ''}${companyName ? ` / ${companyName}` : ''} ("SELLER") and Propertio Inc ("BROKER"), the licensed real estate broker providing brokerage services through the  www.helloReeve.com platform ("Website").`

    const blocks = [
        {
            mainBolded: '1. Authority to Sell Property:',
            main: `SELLER gives BROKER the EXCLUSIVE RIGHT TO SELL the real and personal property (collectively "Property") described below, at the price and terms described below, beginning on ${listingDate.toLocaleDateString('en-US')} or when payment of compensation shown in paragraph 7 (a) is received, whichever is later, and terminating at 11:59 p.m. on ${new Date(new Date(listingDate).setFullYear(listingDate.getFullYear() + 1)).toLocaleDateString('en-US')} ("Termination Date") Should the Property not sell within the initial listing term, the BROKER agrees to extend the listing agreement for an additional term of 12 months, at SELLER's written request submitted at least 3 days prior to expiration, and at no additional cost to SELLER. \n This Property will be offered to any person without regard to race, color, religion, sex, handicap, familial status, national origin, or any other factor protected by federal, state, or local law. SELLER certifies and represents that she/he/it is legally entitled to convey the Property and all improvements.`
        },
        {
            mainBolded: '2. Description of Property:',
            main: `${address}, ${city}, ${state} ${zipCode}`
        },
        {
            mainBolded: '3. Price and Terms:',
            main: 'The property is offered for sale on the following terms or on any other terms acceptable to SELLER:',
            sub: [
                `(a) Price: $${price} The SELLER determined the listing price without any assistance from the BROKER.`,
                `(b) Financing Terms: ${financialTerms}`]
        },
        {
            mainBolded: '4. Multiple Listing Service:',
            main: 'Placing the Property in a multiple listing service (the "MLS") is beneficial to SELLER because the Property will be exposed to a large number of potential buyers. As a MLS participant, BROKER is obligated to timely deliver this listing to the MLS. This listing will be promptly published in the MLS. SELLER authorizes BROKER to report to the MLS this listing information and price, terms, and financing information on any resulting sale for use by authorized Board / Association members and MLS participants and subscribers.'
        },
        {
            mainBolded: '5. BROKER Authority:',
            main: 'SELLER authorizes BROKER to',
            sub: [`(a) List the property on the ${mlsName}`,
                '(b) Advertise the Property as BROKER deems advisable, described on the Website, and as SELLER requested, including advertising the Property on the Internet',
                '(c) Virtual Office Websites notice: Some real estate brokerages offer real estate brokerage services online. These websites are referred to as Virtual Office Websites ("VOWs"). An automated estimate of market value or reviews and comments about a property may be displayed in conjunction with a property on some VOWs. Anyone who registers on a VOW may gain access to such automated valuations or comments and reviews about any property displayed on a VOW',
                '(d) Offer compensation to cooperating brokers.',
                '(e) Perform the following optional services, described on the Website, if requested and paid for by SELLER through the Website: Custom Yard Sign, Open House Announcements, BROKER Support.']
        },
        {
            mainBolded: '6. SELLER Obligations:',
            main: "In consideration of BROKER's obligations, SELLER agrees to:",
            sub: ['(a) Indemnify BROKER and hold BROKER harmless from losses, damages, costs, and expenses of any nature, including attorney\'s fees, and from liability to any person, that BROKER incurs because of (1) SELLER\'s negligence, representations, misrepresentations, actions, or inactions; (2) the use of a lock box; (3) the existence of undisclosed material facts about the Property; or (4) a court or arbitration decision that a broker who was not compensated in connection with a transaction is entitled to compensation from BROKER. This clause will survive BROKER\'s performance and the transfer of title.',
                '(b) Make all legally required disclosures, including all facts that materially affect the Property\'s value and are not readily observable or known by the buyer.SELLER certifies and represents that SELLER knows of no such material facts (local government building code violations, unobservable defects, etc.). SELLER will immediately inform BROKER of any material facts that arise after signing this Agreement.'
            ]
        },
        {
            mainBolded: '7. Compensation',
            main: '',
            sub: ['(a) Listing BROKER Compensation: SELLER will compensate BROKER for performing the responsibilities delineated in Paragraphs 4 and 5 $149.00 one time fee, upon signing of this agreement, regardless of whether the Property sells and no matter who sells the Property, whether by SELLER, BROKER, or other real estate licensee.',
                `(b) Selling BROKER (Buyer's BROKER) Compensation: SELLER is offering ${commission} commission in the MLS to procuring broker/licensee. SELLER is authorizing BROKER to offer this commission by means of this listing agreement and further authorizes BROKER to advertise such commission offer in the MLS on behalf of the SELLER to brokers in an effort to procure a buyer for Property. If a sales contract is fully executed by BUYER/SELLER or if SELLER, by means of any agreement, transfers title of Property to another party in which a broker was the procuring cause by evidence that the broker's name is on the sales contract, and successfully closes transaction, SELLER gives broker the authority to inform the title company/closing agent or attorney representing SELLER to include such commission/bonus, exactly as stated in the MLS, on the HUD1 settlement statement, as an item to be paid by the SELLER, and gives the closing agent such authority to disburse said commission/bonus to the procuring broker at closing. The only exception to this would be if SELLER and broker have agreed to other commission/bonus terms than what is being advertised in the MLS by attaching this language as an addendum to the final contract. If the property is sold to a buyer without the assistance of a broker, there is no additional compensation offered other than the fee SELLER already paid upfront'.`]
        },
        {
            mainBolded: '8. Brokerage Relationship:',
            main: 'BROKER will have no brokerage relationship with SELLER.',
            subTitle: 'NO BROKERAGE RELATIONSHIP NOTICE',
            sub: [
                'FLORIDA LAW REQUIRES THAT REAL ESTATE LICENSEES WHO HAVE NO BROKERAGE RELATIONSHIP WITH A POTENTIAL SELLER/OWNER OR BUYER/TENANT DISCLOSE THEIR DUTIES TO SELLERS AND BUYERS.',
                'As a real estate licensee who has no brokerage relationship with you, Propertio Inc and its associates owe to you the following duties:',
                '1. Dealing honestly and fairly;',
                '2. Disclosing all known facts that materially affect the value of residential real property which are not readily observable to the buyer/tenant.',
                '3. Accounting for all funds entrusted to the licensee. PLEASE NOTE: Propertio Inc does NOT maintain an escrow or trust account, and therefore it can not be named as escrow agent on a contract for purchase.'
            ]
        },
        {
            mainBolded: '9. Miscellaneous:',
            main: 'This Agreement is the entire agreement between SELLER and BROKER. No prior or present agreements or representations will be binding on SELLER or BROKER unless included in this Agreement. Electronic signatures are acceptable and will be binding. Signatures, initials, and modifications communicated by facsimile will be considered as originals.'
        },
        {
            mainBolded: '10. Additional Terms:',
            main: '',
            sub: [
                '(a) "Cancel any time, no penalty, no questions asked":SELLER reserves the right to cancel this agreement at any time, without any additional fee or penalty, no questions asked. However, cancellation of agreement does not release SELLER from obligations under Paragraphs 6 and 7 above. No refund of fees already paid will be issued upon cancellation if the service has been provided and the property has already been listed in the MLS. A full refund will be issued if cancellation occurs immediately after placing the order and before the property being entered in the MLS.',
                '(b) "Free and timely listing updates" : BROKER will perform timely updates to the MLS listing as needed and requested by SELLER throughout the listing agreement, at no additional cost to SELLER. All changes must be requested by SELLER through the website.',
                '(c) "SELLER to provide selling info" : SELLER, upon entering into a contract of sale with a buyer, agrees to notify BROKER, within 48 hours, of the fact that Property is under contract, \'and will advise BROKER of the name and phone number of the settlement agent or attorney and provide a complete fully executed copy of the purchase/sale agreement and all addendum thereto. The failure to do so may result in an MLS fine between $500 to $1,500 to BROKER (subject to change at any time by MLS), which will be charged to the SELLER collectable immediately and/or through judicial action at the option of BROKER. Upon the sale of the Property, SELLER agrees to deliver to BROKER, the following documents or information:',
                '(i) Copy of HUD-1 Settlement Statement or Closing Statement/Disclosure',
                '(ii) Copy of Sales Contract including all addenda and amendments, if any, and written verification by the escrow agent that all required deposit(s) have been paid.',
                '(d) "Unauthorized listing" : The BROKER reserves the right to cancel the listing if misleading or false information has been provided, if the listing party has impersonated the actual owner without authorization, if there is any legal matter that hinders the free sale of the property or presents a legal risk for the BROKER. No refund will be issued in this case, when the BROKER decides to cancel the listing unilaterally under this paragraph.',
                '(e) "Copyrighted artwork" : Images and photographs downloaded from anywhere on the Internet (including from previous listings) are "Copyrighted" and including them in your photo set submitted to BROKER without proper written authorization from the owner of the rights puts SELLER and BROKER at legal risk. Should BROKER receive a violation notice from the MLS or the Realtors Association regarding Copyrighted or unauthorized material posted in the listing, an email notice will be sent to the owner for correction. If no satisfactory correction is submitted within 24 hours,BROKER reserves the right to cancel the listing. No refund will be issued in this case. Customer releases, holds harmless and indemnifies the BROKER form and of any and all liability and responsibility for the publishing of any copyrighted material and will release the BROKER form any legal action (including complains to the Association or Realtors or MLS) that might arise from such usage.',
                '(f) "Free listing agreement extension until sold" : Should the Property not sell within the initial listing term, the BROKER agrees to extend the listing agreement for an additional term of up to 60 months, at SELLER\'s request, immediately prior to initial expiration, and at no additional cost to SELLER.',
                '(g) "Protection period" : For ninety (90) days following expiration of this Agreement, any cooperating broker shall continue to be entitled to the compensation described in this Agreement should SELLER transfer Property or any interest in the Property to any ready, willing and able prospects procured regarding the Property prior to expiration.',
                '(h) "Abandoned listing" : Should the SELLER repeatedly cease to respond to prospective buyer(s)/agent(s) inquiries and BROKER\'s attempts to contact (via email and telephone) for more than 1 week, the listing shall be considered "abandoned" and BROKER shall have the right to cancel the listing. No refund will be issued in this case.'
            ]
        }
    ]
    const acknowledgement = [
        `The SELLER has reviewed the foregoing information contained in this Data Entry Form and acknowledges the information to be true and correct to the best knowledge of the SELLER. The SELLER agrees to indemnify and hold harmless the ${mlsName}, the Originating Board, and their employees, the Listing BROKER and licensees, the Selling BROKER and licensees, and all other cooperating BROKERs and licensees against any and all claims or liability (including attorney fees) arising from any breach of warranty by SELLER or from any incorrect information supplied by SELLER or from any facts concerning the Property which was known or reasonably should have been known to SELLER but not disclosed by SELLER. At the request of the Listing BROKER, unless otherwise properly indicated on this Data Entry form,${mlsName} will electronically transmit information about the SELLER property to Internet web sites to aid in the marketing of the Property for sell.${mlsName} shall retain and make available all such data and photographs to all its participants for an indefinite period. ${mlsName} assumes no responsibility or liability to SELLER for errors or omissions on this Data Entry Form or in the ${mlsName} computer system. The SELLER here by authorizes Listing BROKER to file this Data Entry form with the ${mlsName} and the SELLER will provide timely notice of all status changes.`,
        `Copy returned to SELLER on ${listingDate.toLocaleDateString('en-US')} by email and available to SELLER at any time in SELLER's personal dashboard on the website.`
    ]

    return { blocks, acknowledgement, title, subtitle }
}
