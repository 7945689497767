import type { RentalListingAgreement } from './types'

export const getRentalText = (listingDate: Date, {
    address,
    city,
    state,
    zipCode,
    price,
    mlsName,
    commission,
    leaseTerm,
    ownerName,
    coownerNames,
    companyName
}: RentalListingAgreement) => {
    const title = 'Limited Service Rental Listing Agreement, Disclosures, and Disclaimers'

    const subtitle = `This Limited Service Listing Agreement ("Agreement") is between ${ownerName}${coownerNames ? `, ${coownerNames}` : ''}${companyName ? ` / ${companyName}` : ''} ("Owner") and Propertio Inc ("BROKER"), the licensed real estate broker providing brokerage services through the  www.helloReeve.com platform ("Website").`
    const blocks = [{
        mainBolded: '1. Authority to Lease Property:',
        main: `Owner gives BROKER the EXCLUSIVE RIGHT TO SECURE A TENANT for the real and personal property (collectively "Property") described below, at the price and terms described below, beginning on ${listingDate.toLocaleDateString('en-US')} or when payment of compensation shown in paragraph 7 (a) is received, whichever is later, and terminating at 11:59 p.m. on ${new Date(new Date(listingDate).setFullYear(listingDate.getFullYear() + 1)).toLocaleDateString('en-US')} ("Termination Date") Should the Property not rent within the initial listing term, the BROKER agrees to extend the listing agreement for an additional term of 12 months, at Owner's written request submitted at least 3 days prior to expiration, and at no additional cost to Owner. \n This Property will be offered to any person without regard to race, color, religion, sex, handicap, familial status, national origin, or any other factor protected by federal, state, or local law. Owner certifies and represents that she/he/it is legally entitled to convey the Property and all improvements.`
    },
    { mainBolded: '2. Description of Property:', main: `${address}, ${city}, ${state} ${zipCode}` },
    {
        mainBolded: '3. Rental Rate and Terms:',
        main: 'The property is offered for rent on the following terms or on any other terms acceptable to Owner:',
        sub: [
            `(a) Price: $${price} / month. The Owner determined the price without any assistance from the BROKER`,
            `(b) Rental Period: ${leaseTerm}`,
            '(c) Advanced Rents, Deposits and Fees: Advanced rents and deposits will be held by Owner in a Florida financial institution, as required by Florida Landlord and Tenant law.',
            '(d) Taxes: Leases for a term of 6 months or less are subject to state tax on transient rentals and to local tax on tourist development and impact. The Owner is responsible for timely collecting and remitting said taxes.']
    },
    {
        mainBolded: '4. Multiple Listing Service:',
        main: 'Placing the Property in a multiple listing service (the "MLS") is beneficial to Owner because the Property will be exposed to a large number of potential tenants. As a MLS participant, BROKER is obligated to timely deliver this listing to the MLS. This listing will be promptly published in the MLS. Owner authorizes BROKER to report to the MLS this listing information and price, terms, and financing information on any resulting rental for use by authorized Board / Association members and MLS participants and subscribers.'
    },
    {
        mainBolded: '5. BROKER Authority:',
        main: 'Owner authorizes BROKER to',
        sub: ['(a) Advertise the Property as BROKER deems advisable, described on the Website, and as Owner requested, including advertising the Property on the Internet',
            '(b) Virtual Office Websites: Some real estate brokerages offer real estate brokerage services online. These websites are referred to as Virtual Office Websites (“VOW”). An automated estimate of market value or reviews and comments about a property may be displayed in conjunction with a property on some VOWs. Anyone who registers on a Virtual Office Web site may gain access to such automated valuations or comments and reviews about any property displayed on a VOW. Owner understands and agrees to authorize that a VOW may display automated valuations or comments/reviews (blogs) about this Property.',
            '(c) Offer compensation to cooperating brokers.',
            '(d) Perform the following optional services, described on the Website, if requested and paid for by Owner at Website check out or if included in the package selected: Custom Yard Sign, Professional Pictures, BROKER Support.']
    },
    {
        mainBolded: '6. Owner Obligations:',
        main: "In consideration of BROKER's obligations, Owner agrees to:",
        sub: ['(a) To indemnify and hold harmless BROKER and BROKER\'s officers, directors, agents and employees from all claims, demands, causes of action, costs and expenses, including reasonable attorneys\' fees at all levels, and from liability to any person, to the extent based on Owner\'s misstatement, negligence, action, inaction or failure to perform the obligations of this contract or any lease or agreement with a vendor; or the existence of undisclosed material facts about the Property. This subparagraph will survive BROKER\'s performance and the transfer of title.',
            '(b) To reasonably inspect the Property before allowing the tenant to take possession and to make the repairs necessary to transfer a reasonably safe dwelling unit to the tenant.',
            '(c) To perform any independent investigations to determine whether the local municipality where the Property is located has adopted an ordinance that prohibits property owners from renting to sexual offenders/predators. For information regarding these types of ordinances in your county, search county records and/or log on to www.municode.com. Owner acknowledges that it is the Owner\'s responsibility to research the local ordinances to determine whether or not such ordinance exists and to determine whether a tenant is suitable for rental if such ordinance exists. Owner understands this is not a warranty of any kind and is not intended to be a substitute for any independent investigations Owner may wish to make.',
            '(d) The Owner understands that it is his/her responsibility to respond to requests for information, schedule appointments and show the property and all other activities related to the transaction.'
        ]
    },
    {
        mainBolded: '7. Compensation',
        main: '',
        sub: ['(a) Listing BROKER Compensation: Owner will compensate BROKER for performing the responsibilities delineated in Paragraphs 4 and 5 $149.00 one time fee, upon signing of this agreement, regardless of whether the Property rents and no matter who rents the Property, whether by Owner, BROKER, or other real estate licensee.',
            `(b) Selling BROKER (Tenant's BROKER) Compensation: Owner is offering ${commission} commission in the MLS to procuring broker/licensee. Owner is authorizing BROKER to offer this commission by means of this listing agreement and further authorizes BROKER to advertise such commission offer in the MLS on behalf of the Owner to brokers in an effort to procure a tenant for Property. If the property is rented to a tenant without the assistance of a broker, there is no additional compensation offered other than the fee Owner already paid upfront.`]
    },
    {
        mainBolded: '8. Brokerage Relationship:',
        main: 'BROKER will have no brokerage relationship with Owner.',
        subTitle: 'NO BROKERAGE RELATIONSHIP NOTICE',
        sub: [
            'FLORIDA LAW REQUIRES THAT REAL ESTATE LICENSEES WHO HAVE NO BROKERAGE RELATIONSHIP WITH A POTENTIAL SELLER/OWNER OR BUYER/TENANT DISCLOSE THEIR DUTIES TO SELLERS AND BUYERS.',
            'As a real estate licensee who has no brokerage relationship with you, Propertio Inc and its associates owe to you the following duties:',
            '1. Dealing honestly and fairly;',
            '2. Disclosing all known facts that materially affect the value of residential real property which are not readily observable to the buyer/tenant.',
            '3. Accounting for all funds entrusted to the licensee. PLEASE NOTE: The BROKER does NOT maintain an escrow or trust account, and therefore it can not be named as escrow agent on any contracts.'
        ]
    },
    {
        mainBolded: '9. Miscellaneous:',
        main: 'This Agreement is the entire agreement between Owner and BROKER. No prior or present agreements or representations will be binding on Owner or BROKER unless included in this Agreement. Electronic signatures are acceptable and will be binding. Signatures, initials, and modifications communicated by facsimile will be considered as originals.'
    },
    {
        mainBolded: '10. Additional Terms:',
        main: '',
        sub: [
            '(a) "Cancel any time, no penalty, no questions asked":Owner reserves the right to cancel this agreement at any time, without any additional fee or penalty, no questions asked. However, cancellation of agreement does not release Owner from obligations under Paragraphs 6 and 7 above. No refund of fees already paid will be issued upon cancellation if the service has been provided and the property has already been listed in the MLS. A full refund will be issued if cancellation occurs immediately after placing the order and before the property being entered in the MLS.',
            '(b) "Free and timely listing updates" : BROKER will perform timely updates to the MLS listing as needed and requested by Owner throughout the listing agreement, at no additional cost to Owner. All changes must be requested by Owner through the website.',
            '(c) "Owner to provide selling info" : Owner, upon entering into a contract of sale with a buyer, agrees to notify BROKER, within 24 hours, of the fact that Property is under contract or rented, and will provide BROKER a complete fully executed copy of the lease agreement and all addendum thereto. The failure to do so may result in an MLS fine between $500 to $1,500 to BROKER (subject to change at any time by MLS), which will be charged to the Owner collectable immediately and/or through judicial action at the option of BROKER.',
            '(d) "Unauthorized listing" : The BROKER reserves the right to cancel the listing if misleading or false information has been provided, if the listing party has impersonated the actual owner without authorization, if there is any legal matter that hinders the free sale of the property or presents a legal risk for the BROKER. No refund will be issued in this case, when the BROKER decides to cancel the listing unilaterally under this paragraph.',
            '(e) "Copyrighted artwork" : Images and photographs downloaded from anywhere on the Internet (including from previous listings) are "Copyrighted" and including them in your photo set submitted to BROKER without proper written authorization from the owner of the rights puts Owner and BROKER at legal risk. Should BROKER receive a violation notice from the MLS or the Realtors Association regarding Copyrighted or unauthorized material posted in the listing, an email notice will be sent to the owner for correction. If no satisfactory correction is submitted within 24 hours,BROKER reserves the right to cancel the listing. No refund will be issued in this case. Customer releases, holds harmless and indemnifies the BROKER form and of any and all liability and responsibility for the publishing of any copyrighted material and will release the BROKER form any legal action (including complains to the Association or Realtors or MLS) that might arise from such usage.',
            '(f) "Free listing agreement extension until rented" : Should the Property not rent within the initial listing term, the BROKER agrees to extend the listing agreement for an additional term of up to 12 months, at Owner\'s request, immediately prior to initial expiration, and at no additional cost to Owner.',
            '(g) "Protection period" : For ninety (90) days following expiration of this Agreement, any cooperating broker shall continue to be entitled to the compensation described in this Agreement should Owner transfer Property or any interest in the Property to any ready, willing and able prospects procured regarding the Property prior to expiration.',
            '(h) "Abandoned listing" : Should the Owner repeatedly cease to respond to prospective buyer(s)/agent(s) inquiries and BROKER\'s attempts to contact (via email and telephone) for more than 2 weeks, the listing shall be considered "abandoned" and BROKER shall have the right to cancel the listing. No refund will be issued in this case.'
        ]
    }
    ]
    const acknowledgement = [
        `The Owner has reviewed the foregoing information contained in this Data Entry Form and acknowledges the information to be true and correct to the best knowledge of the Owner. The Owner agrees to indemnify and hold harmless the ${mlsName}, the Originating Board, and their employees, the Listing BROKER and licensees, the Selling BROKER and licensees, and all other cooperating BROKERs and licensees against any and all claims or liability (including attorney fees) arising from any breach of warranty by Owner or from any incorrect information supplied by Owner or from any facts concerning the Property which was known or reasonably should have been known to Owner but not disclosed by Owner. At the request of the Listing BROKER, unless otherwise properly indicated on this Data Entry form,${mlsName} will electronically transmit information about the Owner property to Internet web sites to aid in the marketing of the Property for rent.${mlsName} shall retain and make available all such data and photographs to all its participants for an indefinite period. ${mlsName} assumes no responsibility or liability to Owner for errors or omissions on this Data Entry Form or in the ${mlsName} computer system. The Owner here by authorizes Listing BROKER to file this Data Entry form with the ${mlsName} and the Owner will provide timely notice of all status changes.`,
        `Copy returned to Owner on ${listingDate.toLocaleDateString('en-US')} by email and available to Owner at any time in Owner's personal dashboard on the website.`
    ]
    return {
        blocks,
        acknowledgement,
        title,
        subtitle
    }
}
